// extracted by mini-css-extract-plugin
export var cardButton = "style-module--cardButton--R8cD7";
export var cardDescription = "style-module--cardDescription--BEro5";
export var cardTitle = "style-module--cardTitle--o1g0k";
export var colorLine = "style-module--colorLine--3+w1Z";
export var container = "style-module--container--ErnAP";
export var image = "style-module--image--onYkc";
export var partner = "style-module--partner--UjMhd";
export var partners = "style-module--partners--oEoj1";
export var section = "style-module--section--GUciB";
export var title = "style-module--title--iCKhq";