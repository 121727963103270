// extracted by mini-css-extract-plugin
export var cardButton = "style-module--cardButton--MPsWg";
export var cardDescription = "style-module--cardDescription--9eKtA";
export var cardTitle = "style-module--cardTitle--0nXFs";
export var colorLine = "style-module--colorLine--k-X3C";
export var container = "style-module--container--Jmsgq";
export var image = "style-module--image--o4RKG";
export var isStyleH1 = "style-module--is-style-h1--hp2uo";
export var isStyleH2 = "style-module--is-style-h2--i-k14";
export var isStyleH3 = "style-module--is-style-h3--aUGep";
export var isStyleH4 = "style-module--is-style-h4--kWlZT";
export var isStyleH5 = "style-module--is-style-h5--PwaRN";
export var isStyleH6 = "style-module--is-style-h6--kLqX2";
export var partner = "style-module--partner--1brmM";
export var partners = "style-module--partners--ugkfk";
export var section = "style-module--section---liTy";
export var title = "style-module--title--VNUd4";