// extracted by mini-css-extract-plugin
export var cardButton = "style-module--cardButton--ssMTk";
export var cardDescription = "style-module--cardDescription--DOXJp";
export var cardTitle = "style-module--cardTitle---EuP+";
export var colorLine = "style-module--colorLine--A0R2s";
export var container = "style-module--container--6A1Y3";
export var image = "style-module--image--YW6vR";
export var isStyleH1 = "style-module--is-style-h1--5LAFY";
export var isStyleH2 = "style-module--is-style-h2--oIT+u";
export var isStyleH3 = "style-module--is-style-h3--DgQbI";
export var isStyleH4 = "style-module--is-style-h4--++dZB";
export var isStyleH5 = "style-module--is-style-h5--fgMVe";
export var isStyleH6 = "style-module--is-style-h6--2WTnm";
export var partner = "style-module--partner--oxeq9";
export var partners = "style-module--partners--Yb39A";
export var richDescription = "style-module--richDescription--rfrPX";
export var section = "style-module--section--q2dtu";
export var title = "style-module--title--6ONKZ";