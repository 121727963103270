import React from "react"
import Layout from "../components/layout"
import SEO from "../components/partners/seo"
import HeroHeader from "../components/partners/heroHeader"
import PremiumPartners from "../components/partners/premiumPartners"
import PlatinumPartners from "../components/partners/platinumPartners"
import OtherPartners from "../components/partners/otherPartners"
import MorePartners from "../components/partners/morePartners"
import AssociationPartners from "../components/partners/associationPartners"
import BottomCTa from "../components/partners/moreInfoCta/index"

const Partners = () => {
  return (
    <Layout>
      <SEO />
      <HeroHeader />
      <PlatinumPartners />
      <PremiumPartners />
      <OtherPartners />
      <AssociationPartners />
      <MorePartners />
      <BottomCTa />
    </Layout>
  )
}

export default Partners
