// extracted by mini-css-extract-plugin
export var cardButton = "style-module--cardButton--OGu9U";
export var cardDescription = "style-module--cardDescription--9yMgC";
export var cardTitle = "style-module--cardTitle--jpQe9";
export var colorLine = "style-module--colorLine--OJHP+";
export var container = "style-module--container--hJ1FW";
export var image = "style-module--image--UucPA";
export var isStyleH1 = "style-module--is-style-h1--OZfJV";
export var isStyleH2 = "style-module--is-style-h2--qDNMe";
export var isStyleH3 = "style-module--is-style-h3--h58Gg";
export var isStyleH4 = "style-module--is-style-h4--GAWcH";
export var isStyleH5 = "style-module--is-style-h5--+OnqW";
export var isStyleH6 = "style-module--is-style-h6--G3zAI";
export var partner = "style-module--partner--nq-CS";
export var partners = "style-module--partners--g2PT1";
export var section = "style-module--section--gwXy3";
export var title = "style-module--title--JEamk";