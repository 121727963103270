import React, { useContext, useState, useEffect } from "react"
import * as styles from "./style.module.scss"
import { useStaticQuery, graphql, navigate } from "gatsby"
import { LocationsContext } from "../../../contexts/locationsContext"
import { v4 as uuid } from "uuid"
import { GatsbyImage } from "gatsby-plugin-image"
import "../PartnersModal/modalStyles.scss"

const PremiumPartners = () => {
  const data = useStaticQuery(graphql`
    {
      sanityPartnersPage {
        platinumPartners
      }
      allSanityIndividualPartners(
        filter: { partnerType: { eq: "platinumPartner" } }
      ) {
        edges {
          node {
            _id
            partnerName
            logo {
              asset {
                url
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: NONE
                  formats: [AUTO, WEBP]
                )
              }
            }
            description
            colorlist
            descriptionPop {
              _rawColumnContent
            }
            descriptionRich {
              _rawColumnContent
            }
            rightColumn {
              _rawColumnContent
            }
            script
            slug {
              current
            }
            loactions {
              locations {
                countryCode
              }
            }
          }
        }
      }
    }
  `)

  const { currentLocation } = useContext(LocationsContext)
  const [integrations, setIntegrations] = useState(
    data.allSanityIndividualPartners.edges
  )

  useEffect(() => {
    if (currentLocation.countryCode === "gl") {
      setIntegrations(data.allSanityIndividualPartners.edges)
    } else {
      const filteredData = data.allSanityIndividualPartners.edges.filter(
        integrations =>
          integrations.node.loactions && // prevent error, if it's null, return false
          integrations.node.loactions.locations
            .map(location => location.countryCode) // reconstruct country code as array of string
            .includes(currentLocation.countryCode) // chech if the country code same as current country code, then return true
      )
      setIntegrations(filteredData)
    }
  }, [currentLocation])

  const handleLearnMoreClick = slug => {
    navigate(`/partners/${slug}`)
  }

  return (
    <div className={styles.section}>
      <div className={styles.container}>
        {integrations.length > 0 ? (
          <h3 className={styles.title}>
            {data.sanityPartnersPage.platinumPartners}
          </h3>
        ) : null}
        <div className={styles.partners}>
          {integrations.length > 0 ? (
            integrations.map(partner => (
              <div key={uuid()} className={styles.partner}>
                {partner.node.logo && (
                  <GatsbyImage
                    image={partner.node.logo.asset.gatsbyImageData}
                    alt={partner.node.logo.asset.originalFilename}
                    className={styles.image}
                    imgStyle={{ objectFit: "contain" }}
                  />
                )}
                <h3 className={styles.cardTitle}>{partner.node.partnerName}</h3>
                <p className={styles.cardDescription}>
                  {partner.node.description}
                </p>

                <button
                  className="learnMore"
                  id="main"
                  onClick={() =>
                    handleLearnMoreClick(partner.node.slug.current)
                  }
                >
                  Learn More
                </button>
                <span
                  style={{ backgroundColor: partner.node.colorlist }}
                  className={styles.colorLine}
                />
              </div>
            ))
          ) : (
            <p className="empty-notice">
              Sorry, no partners avalible in region:{" "}
              {currentLocation.countryName}
            </p> // if no integration avalible, return this paragraph
          )}
        </div>
      </div>
    </div>
  )
}

export default PremiumPartners
